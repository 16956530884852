<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload
                :image-url.sync="form.avatar"
                :image-text="'avatar'"
                :image-folder="'logistics/riders'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Rider Information"
        subtitle="Please provide this rider’s information"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="firstname"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.firstname"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="lastname"
                :rules="validateName()"
              >
                <el-input
                  type="text"
                  v-model="form.lastname"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Phone number"
                prop="phone_number"
                :rules="validatePhone()"
              >
                <eden-phone-input :input.sync="form.phone_number" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="Email address"
                prop="email"
                :rules="validateEmail()"
              >
                <el-input
                  type="text"
                  v-model="form.email"
                  placeholder="Enter email address"
                  :disabled="action == 'edit'"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Logistics Company"
                prop="company_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.company_id"
                  filterable
                  placeholder="Enter Company Name"
                >
                  <el-option
                    v-for="company in companies"
                    :key="company.id"
                    :label="company.name"
                    :value="company.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Bank Information"
        subtitle="This is where they’ll receive their payouts."
      >
        <template slot="form-fields">
          <el-row>
            <el-col>
              <el-form-item label="Bank Name" prop="banks">
                <el-select
                  :value-key="'id'"
                  filterable
                  v-model="form.bank"
                  placeholder="Select bank"
                >
                  <el-option
                    v-for="(item, index) in banks"
                    :key="index"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="Account Number" prop="AccNumber">
                <el-input
                  v-model="form.account_number"
                  type="number"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="bankVerified || action == 'edit'"
                label="Account Name"
                prop="AccName"
              >
                <el-input
                  v-model="form.account_name"
                  type="text"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="cancel">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              :disabled="isAddButtonDisabled"
              >Add Rider
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import details from "@/requests/vendors/details.js";
import companies from "@/requests/logistics/directory/companies";
import riders from "@/requests/marketplace-logistics/riders";
export default {
  name: "RidersForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    rider: {
      type: Object,
      default() {
        return {};
      },
    },
    banks: {
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadingAvatar: false,
      form: {
        avatar: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
        company_id: "",
        bank: [],
        account_number: null,
        account_name: "",
      },
      companies: [],
      adding: false,
      updating: false,
      bankVerified: false,
      isLoading: this.loading,
    };
  },
  watch: {
    "form.bank": function () {
      this.checkAndVerifyBankDetails();
    },
    "form.account_number": function (newaccount_number) {
      if (newaccount_number && newaccount_number.toString().length === 10) {
        this.checkAndVerifyBankDetails();
      }
    },
  },
  created() {
    this.getCompanies();
    if (this.action === "edit") {
      this.prefillForm();
    }
  },
  computed: {
    selectedbankBankCode() {
      return this.form.bank ? this.form.bank.bank_code : "";
    },
    selectedbankName() {
      return this.form.bank ? this.form.bank.name : "";
    },
    isAddButtonDisabled() {
      return (
        !this.form.firstname ||
        !this.form.lastname ||
        !this.form.phone_number ||
        !this.form.email ||
        !this.form.company_id ||
        !this.form.bank ||
        !this.form.account_number ||
        !this.form.account_name
      );
    },
  },
  methods: {
    prefillForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.rider[key];
      });
      const foundBank = this.banks.find(
        (bank) => bank.name === this.rider.bank_name,
      );
      if (foundBank) {
        this.form.bank = foundBank;
      }
    },
    getCompanies() {
      companies
        .index(1)
        .then((response) => {
          if (response.data.status) {
            this.companies = response.data.data;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    checkAndVerifyBankDetails() {
      if (
        this.form.bank &&
        this.form.account_number &&
        this.form.account_number.toString().length === 10
      ) {
        this.verifyBankDetails();
      }
    },
    cancel() {
      this.$router.push({
        name: "marketplace-logistics.directory.index",
        query: { t: "riders" },
      });
    },
    verifyBankDetails() {
      this.isLoading = true;
      const payload = {
        bank_code: this.selectedbankBankCode,
        account_number: this.form.account_number,
      };
      details
        .verifyBank(payload)
        .then((response) => {
          if (response.data.status) {
            this.form.account_name = response.data.data.account_name;
            this.bankVerified = true;
            if (this.action === "add") {
              this.$message.success(response.data.message);
            }
          }
        })
        .catch(() => {
          this.$message.error(
            "Incorrect bank account information, Please check that the details entered are correct",
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const formData = { ...this.form, bank_name: this.selectedbankName };
        delete formData.bank;
        riders
          .add(formData)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$router.push({
                name: "marketplace-logistics.directory.index",
                query: { t: "riders" },
              });
              setTimeout(() => {
                this.$router.go(0);
              }, 100);
            }
          })
          .catch((error) => {
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          })
          .finally(() => {
            this.adding = false;
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const formData = { ...this.form, bank_name: this.selectedbankName };
        delete formData.bank;
        this.updating = true;
        riders
          .update(this.rider.uuid, formData)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$router.push({
                name: "marketplace-logistics.directory.rider",
                params: { id: this.rider.uuid },
              });
              setTimeout(() => {
                this.$router.go(0);
              }, 100);
            }
          })
          .catch((error) => {
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          })
          .finally(() => {
            this.updating = false;
          });
      });
    },
  },
};
</script>
