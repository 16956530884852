import axios from "axios";

// window.axios.defaults.baseURL =
//   "https://api-marketplace.edenlife.ng/api/v1/marketplace_crm/";

export default {
  list(id) {
    return axios.get(`marketplace_crm/vendors/${id}/general-info`);
  },

  workingHours(id) {
    return axios.get(`marketplace_crm/vendors/${id}/working-hours`);
  },

  orders(id, params) {
    return axios.get(`marketplace_crm/vendors/${id}/orders?${params}`);
  },

  reviews(id, page) {
    return axios.get(`marketplace_crm/vendors/${id}/reviews?page=${page}`);
  },

  reviewsFilter(id, params = "") {
    return axios.get(`marketplace_crm/vendors/${id}/reviews?${params}`);
  },

  ordersFilter(id, params = "") {
    return axios.get(`marketplace_crm/vendors/${id}/orders?${params}`);
  },

  vendorProducts(id) {
    return axios.get(`marketplace_crm/products/vendor/${id}`);
  },

  search(id, query) {
    return axios.get(`marketplace_crm/vendors/${id}/orders?search=${query}`);
  },

  export(payload) {
    return axios.post(`marketplace_crm/orders/export`, payload);
  },
  products(id, params) {
    return axios.get(`marketplace_crm/products/vendor/${id}`, {
      params: params,
    });
  },
  categories(id) {
    return axios.get(`marketplace_crm/categories/vendor/${id}`);
  },
  createCategory(id, payload) {
    return axios.post(
      `marketplace_crm/categories/vendor/${id}/create_category`,
      payload,
    );
  },
  createVendorProduct(id, payload) {
    return axios.post(
      `marketplace_crm/products/vendor/${id}/create_product`,
      payload,
    );
  },
  tags(id) {
    return axios.get(`marketplace_crm/tags/${id}`);
  },
  singleProduct(id, productId) {
    return axios.get(
      `marketplace_crm/products/vendor/${id}/get_product/${productId}`,
    );
  },
  editProduct(id, productId, payload) {
    return axios.patch(
      `marketplace_crm/products/vendor/${id}/edit_product/${productId}`,
      payload,
    );
  },
  verifyBank(params) {
    return axios.get(`marketplace_crm/vendors/verify_bank_account`, {
      params: params,
    });
  },
  setBank(data) {
    return axios.put(`marketplace_crm/vendors/bank_settings`, data);
  },
  listBanks() {
    return axios.get(`marketplace_crm/list_banks`);
  },
  deleteProduct(id, payload) {
    return axios.delete(
      `marketplace_crm/products/vendor/${id}/delete_product`,
      payload,
    );
  },
  updateInventory(id, payload) {
    return axios.patch(
      `marketplace_crm/products/vendor/${id}/product_stock`,
      payload,
    );
  },
  publish(id, payload) {
    return axios.post(
      `marketplace_crm/products/vendor/${id}/publish_product`,
      payload,
    );
  },
  duplicate(id, payload) {
    return axios.post(
      `marketplace_crm/products/vendor/${id}/duplicate_product`,
      payload,
    );
  },
  addons(params) {
    return axios.get(`marketplace_crm/addons`, { params });
  },
  createAddons(params, payload) {
    return axios.post(`marketplace_crm/addons?${params}`, payload);
  },
  updateAddons(id, payload) {
    return axios.put(`marketplace_crm/addons/${id}`, payload);
  },
  addon(id, params) {
    return axios.get(`marketplace_crm/addons/${id}`, {
      params,
    });
  },
  deleteAddons(payload) {
    return axios.delete(`marketplace_crm/addons`, payload);
  },
  productOverview(id, params) {
    return axios.get(`marketplace_crm/products/${id}/overview`, { params });
  },
  productAddons(id, params) {
    return axios.get(`marketplace_crm/products/${id}/addons`, { params });
  },
  productReviews(id, params) {
    return axios.get(`marketplace_crm/products/${id}/reviews`, { params });
  },
  bulkCreate(id, payload) {
    return axios.post(
      `marketplace_crm/products/vendor/${id}/create_bulk_products`,
      payload,
    );
  },
  vendorDetails(id, payload) {
    return axios.patch(
      `marketplace_crm/vendors/${id}/business_settings`,
      payload,
    );
  },
  checkDelivery(params) {
    return axios.get(`marketplace_crm/check_delivery_location`, { params });
  },
  refund(payload) {
    return axios.post(`marketplace_crm/orders/refund`, payload);
  },
};
